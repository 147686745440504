import React, { useState } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { CiCalendar } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import './HomeNav.scss';

const HomeNav = ({header}) => {


const [isDropdownOpen,setIsDropdownOpen]=useState(false)

const formatDate = (date) => {
  const options = { day: "numeric", month: "short", year: "numeric" };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
};

const today = new Date();
  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - 1);

  function toggleDropdown (){
    setIsDropdownOpen(!isDropdownOpen)
  }
    

  return (
    <div className='home_nav_cont'>

      <div className='home_nav_top'>
        <div className='home_nav_header'>
            <h4>{header} Overview</h4>
            <IoIosArrowDown
            onClick={()=>toggleDropdown()}
            size={20}
            />
        </div>
        <p>Here's the latest activity on Sonaroid.</p>
      {isDropdownOpen &&
        <div className='home_nav_drop'>
          <p
          onClick={()=>window.location.href="/overview/home" }
          
          >Consumer Overview
          </p>

          <p
            onClick={()=>window.location.href="/overview/home/contributor" }
          >
            Contributor Overview</p>
        </div>}

       
      </div>

      <div className='home_nav_calendar'>
        <div className='home_nav_calendar_one'>
         <CiCalendar
         size={20}
         />
         <p> {`${formatDate(lastMonth)} - ${formatDate(today)}`}</p>
        </div>

        <div className='new_consumer'>
          <FaPlus/>
          <h4>New {header}</h4>
        </div>
      </div>

    </div>
  )
}

export default HomeNav
