import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import './LineChart.scss'
// import { color } from "chart.js/helpers";

// Register the Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DateChart = ({header}) => {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 300 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { offsetWidth } = containerRef.current;
        setDimensions({ width: offsetWidth, height: 300 });
      }
    };

    // Initialize dimensions
    updateDimensions();

    // Update dimensions on window resize
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);


    const data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        datasets: [
          {
            label: "Views",
            data: [30000, 60000, 55000, 50000, 65000, 45000], // Replace with actual data
            borderColor: "black",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            tension: 0.4, // Smooth curve
            pointRadius: 1, // Size of dots on line points
          },
        ],
      };
    
      const options = {
        // responsive: true,
        // maintainAspectRatio: false,
        plugins: {
          legend: { display: false }, // Hide legend
          tooltip: { enabled: true }, // Enable tooltips
        },
        scales: {
          x: {
            grid: { display: true }, // Hide grid lines for X-axis
            ticks: {
                font: {
                    size: 10, // Font size
                    weight: "400", // Font weight
                    family: "Inter", // Font family
                    color:'#9CA0A5'
                  },
              padding: 3, // Add space between X-axis labels and chart area
            },
            offset: false,
            
          },
          y: {
            min:0, // Start closer to the lowest value
            max: 100000, 
            ticks: {
              stepSize: 20000, // Control step size (700/5 = 140)
              callback: (value) => `${value}`, // Customize labels if needed
              font: {
                size: 10, // Font size
                weight: "400", // Font weight
                family: "Inter", // Font family
                color:'#9CA0A5'
              },
            },
            grid: {
              drawBorder: false, // Hide border line
            },
          },
        },
        layout: {
          padding: {
            top: 20, // Add padding at the top of the chart
            bottom: 10, // Add padding at the bottom
            left: 10, // Add padding to the left of the Y-axis
            right: 10, // Add padding to the right
          },
        },
      };

  return (
    <div  
    ref={containerRef}
    
    className ='line_chart_cont'>
      <h3 className="chart_header"> {header}</h3>
      <div style={{height:'200px'}}>
      <Line 
      style={{height:'156px'}}
      data={data} options={options} 
      width={dimensions.width}
      height={dimensions.height - 40} 
      />
      </div>
      
    </div>
  );
};

export default DateChart;
