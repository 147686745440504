import React from 'react';
import './ReportCard.scss';
import logo from '../../../../../src/assets/reportcardlogo.png'

const ReportCard = ({
  contHeight,
  contWidth,
  imgBoxWidth,
  contPadding,
  lineHeight,
  lineRight,
  lineTop,
  lineWidth,
  transformLine,
  transformOrigin,
  headerFont,
  lineBottom,
  lineLeft
}) => {

  const useStyle = {
    height : contHeight,
    width: contWidth,
    padding : contPadding
  }

  const useStyleImg = { 
    width:imgBoxWidth
  }

  const useStyleLine ={
    top: lineTop,
    right: lineRight,
    transform: transformLine,
    transformOrigin: transformOrigin,
    width: lineWidth,
    height: lineHeight,
    position: "absolute",
    backgroundColor:"#ffffff",
    borderRadius: 20,
    content: '""', 
  }
  const useStyleHeader = {
    fontSize: headerFont,
    color:"#FFFFFF",
    width: "71%",
    letterSpacing: "0.3px",
    marginTop: 20,
  }

  const useStyleLineBtm ={
    bottom:lineBottom,
    left:lineLeft,
    transform: transformLine,
    transformOrigin: transformOrigin,
    width: lineWidth,
    height: lineHeight,
    position: "absolute",
    backgroundColor:"#ffffff",
    borderRadius: 20,
    content: '""', 
  }


  return (
    
    <div style={useStyle} className="report-crd-cont">
       <div style={useStyleLine} className="report-crd-cont-line">

        </div>
        <div style={useStyleImg}>
            <img src={logo} alt="logo" />
        </div>
        <p style={useStyleHeader}>African Oil and Gas Supply Chain and Opportunities in the Energy Transistion</p>

        <div className="report-crd-cont-line">

        </div>

       <p className="report-crd-date">September 2024</p>

       <div style={useStyleLineBtm} className="report-crd-cont-line">

      </div>
       
    </div>
    
  )
}

export default ReportCard
