import React from 'react';
import './ReportContentView.scss';
import { BsDownload } from "react-icons/bs";
import { AiOutlineLink } from "react-icons/ai";
import { LuClock3 } from "react-icons/lu";
import { LuCalendarPlus } from "react-icons/lu";
import { VscEye } from "react-icons/vsc";

const ReportContentView = ({docData}) => {


    function formatDate(isoString) {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    function formatDateLong(isoString) {
        const date = new Date(isoString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }


    const viewData = [
    {
        subheader:"Title",
        para:docData.title
    },
    {
        subheader:"Description",
        para:docData.description
    },
    {
        subheader:"Tags",
        para:docData.industries.map(industry => industry.name).join(', ')
    },
    {
        subheader:"Author",
        para:docData.author
    },
    {
        subheader:"Publication Date",
        para:formatDate(docData.publication_date)
    },
    {
        subheader:"Document Type",
        para:docData.document_type
    },
    {
        subheader:"Source",
        para:docData.source
    },
    {
        subheader:"How did you get this document?",
        para:docData.access
    }
    ]
    
    function downloadPDF(){
        const link = document.createElement('a');
        link.href = docData.url;
        link.download = docData.title; // Optional: Set a default file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

  return (

    <div className='report-view-cont'>

      <div className='report-view-header'>

        <div style={{width:'50%'}}>
            <h2>{docData.title}</h2>
        </div>

       

        <div className='report-view-btn-cont'>

            <div 
             onClick={()=>downloadPDF()}
            className='report-view-btn-one'>

                <BsDownload
                    size={20}
                   
                />
                <h4> Download(PDF)</h4>

            </div>

            <div className='report-view-btn-one'>

                <AiOutlineLink
                    size={20}
                />
                <h4> Copy Link </h4>
            </div>

            <div>
                <button
                onClick={()=>window.location.href=`/view/report/pdf/${docData.id}`}
                className='report-view-btn-two'
                > Read Document</button>
            </div>
           
           
        </div>

    </div>


    <div className='report-view-merge'>
        <p
            className='admin-col-status'
            style={{
            color:
                docData.status === "rejected"
                ? "#9E0A05"
                : docData.status === "pending_review"
                ? "#8B5904"
                : docData.status === "withdrawn"
                ? "#4B4F57"
                : "#036B26",

                backgroundColor:
                docData.status === "rejected"
                ? "#FBEAE9"
                : docData.status === "pending_review"
                ? "#FEF0D8"
                : docData.status === "withdrawn"
                ? "#F3F2EF"
                : "#E7F6EC",

            }}
            >
            {docData.status}
        </p>

        
        <h4>Document ID: {docData.id}</h4> 

        </div>
      
      <div className='report-view-content'>

        <div className='report-view-content-one'>

            {viewData.map((item,index)=> (

                 <div  className = "report-view-details" key={index}>
                    <h4>{item.subheader}</h4>
                    <p>{item.para}</p>
                 </div>

            ))}
           

        </div>

        <div className='report-view-content-two'>


            <div className='report-view-profile'>
                <div className='report-view-profile-details'>
                    <div>
                        {/* <img src={docData.created_by.image_url} alt="" /> */}
                    </div>

                    <div>
                        <h4>Contributor</h4>
                        <p>
                            {docData.created_by_last_name + " " + docData.created_by_first_name}
                        </p>
                    </div>
                </div>
               
                <div>
                    <button>
                        View Profile
                    </button>
                </div>
            </div>

            <div className='report-view-content-line'>
                <div>
                    <LuCalendarPlus
                    color='#4B4F57'
                    />
                    <h4>Date Created</h4>
                </div>

                <p>{formatDateLong(docData.created_at)}</p>

            </div>

            <div className='report-view-content-line'>
                <div>
                    <LuClock3
                    color='#4B4F57'
                    />
                    <h4>Last Updated</h4>
                </div>

                <p>{formatDateLong(docData.updated_at)}</p>

            </div>

            <div className='report-right-divider'>

            </div>

            <div className='report-view-content-line'>

                <div>
                    <BsDownload
                    color='#4B4F57'
                    />
                    <h4>Downloads</h4>
                </div>

                <p>{docData.total_downloads}</p>

            </div>

            <div className='report-view-content-line'>
                <div>
                    <VscEye
                    color='#4B4F57'
                    />
                    <h4>Views</h4>
                </div>

                <p>{docData.total_views}</p>

            </div>

            
            
        </div>

      </div>

    </div>
  )
}

export default ReportContentView
