import React from 'react'
// import { useSelector } from "react-redux";
import AdminSidebar from '../Reusable/AdminSidebar/AdminSidebar';
import HomeNav from './HomeNav/HomeNav'
import './Home.scss';
import StatsCont from './StatsCont/StatsCont';
import DateChart from './LineChart/LineChart';
import DonutChart from './DonutChart/DonutChart';
import TreemapChart from './TreeMap/TreeMap';
import RecentActivity from './RecentActivity/RecentActivity';


const Home = () => {
    // const userData = useSelector(state => state.userLogin.userInfo);
    // const firstName = userData.user.first_name;
    // const lastName = userData.user.last_name;

  const labelValues =["Sole Consumers", "Hybrid"]
  const labelValue2 =["Datasets", "Infographics","Documents"]
  const data1 = [60, 40]
  const data2 = [50, 25, 25]
  const backColor1 = ["#007bff", "#000000"] // Blue and Black colors
  const backColor2 = ["#F3A218","#0F973D", "#007bff"] // Blue and Black colors
  return (
    <div className='admin_home_cont'>
      <AdminSidebar/>

      <section className='admin_home_side'>
        <HomeNav
        header={"Consumer"}
        />
        <StatsCont
        box1={"Users"}
        box2 = {"Payments"}
        box3={"Total Views"}
      

        />

        <div className='admin_chart_one'>

          <div className='admin_charts_box'>
            <DateChart
            header={"Views by Date"}
            />
            <DonutChart
            header={"Consumers by Type"}
            dataSet = {data1}
            content={"Total Users"}
            labelValues={labelValues}
            values={"230,032"}
            backGr={backColor1}
            />
          </div>
          <div className='admin_charts_box'>
           
            <DonutChart
            header={"Views by Resources"}
            dataSet = {data2}
            labelValues={labelValue2}
            backGr={backColor2}
            values={"230,032"}
            content={"Total users"}
            />
            <DateChart
             header={"Payment by date"}
            />
          </div>

          <TreemapChart
          header={"User by location"}
          />
        
          
        </div>

        <RecentActivity
        header={"Recent Activity"}
        />

      </section>
    </div>
  )
}

export default Home
