import React from 'react'
import './StatsCont.scss';
import img1 from '../../../../assets/totUsers.png';
import img2 from '../../../../assets/money.png';
import img3 from '../../../../assets/views.png';
import img4 from '../../../../assets/location.png';

const StatsCont = ({
box1,
box2,
box3
}) => {


  return (
    <div className='stats-cont'>

        <div className='stats-content'>
            
            <div>
                <img src={img1} alt="icon" />
                <p>Total {box1}</p>
            </div>

            <h4>230,032</h4>
        </div>

        <div className='stats-content'>

            <div>
            <img src={img2} alt="icon" />
                <p>{box2}</p>
            </div>

            <h4>$65.35k</h4>
        </div>

        <div className='stats-content'>

            <div>
            <img src={img3} alt="icon" />
                <p>{box3}</p>
            </div>

            <h4>234, 890</h4>
        </div>
        
        <div className='stats-content'>

            <div>
            <img src={img4} alt="icon" />
                <p>Locations</p>
            </div>

            <h4>46</h4>
        </div>

       
    </div>
  )
}

export default StatsCont
