import React,{useState,useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './Scroll';
import Login from './components/Authentication/Login/Login';
import Home from './components/AdminDashboard/Home/Home';
import { useSelector } from "react-redux";
import AdminNews from "./components/AdminDashboard/AdminNews/AdminNews";
import CreateNews from "./components/AdminDashboard/AdminNews/CreateNews";
import AdminNewsBookmark from "./components/AdminDashboard/AdminNews/AdminNewsBookmark";
import HomeContributor from "./components/AdminDashboard/Home/HomeContributor";
import AdminContributions from "./components/AdminDashboard/AdminContributions/AdminContributions";
import CreateDocument from "./components/AdminDashboard/CreateDocument/CreateDocument";
import ViewReport from "./components/AdminDashboard/ViewReport/ViewReport";
import ReportPdf from "./components/AdminDashboard/ViewReport/ReportPdf";

function App() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [isLoggedIn] = useState(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return userInfo && Object.keys(userInfo).length > 0;
  });
  
  
  
  const [role, setRole] = useState(null);
  
  const userData = useSelector((state) => state.userLogin.userInfo.user);
  
  

  useEffect(() => {
    
    
    if (userInfo) {
      const accountType = userData.account_type;
      const accountVerified = userData.is_verified;
      if (accountType === "super_admin" && accountVerified === true) {
        setRole('super_admin');
      
      

      } else {
        setRole(null);
       
      }
    } else {
      setRole(null);
    }
  }, [userData,userInfo]);

  return (
    <Router>
       <ScrollToTop/>
      <Routes>
      { role === 'super_admin' && (
        <>
           <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/overview/home" element={<Home />} />
          <Route path="/overview/home/contributor" element={<HomeContributor />} />
          <Route path="/dashboard/news" element={<AdminNews/>} />
          <Route path="/dashboard/news/bookmarks" element={<AdminNewsBookmark/>} />
          <Route path="/dashboard/news/addnews" element={<CreateNews/>} />
          <Route path="/dashboard/contributions" element={<AdminContributions/>} />
          <Route path="/admin/createdoc" element={<CreateDocument/>} />
          <Route path="/dashboard/contributions/report/:id" element={<ViewReport/>} />
          <Route path="/view/report/pdf/:id" element={<ReportPdf/>} />

      
        </>

      )}

      {!isLoggedIn && (
        <>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </>
      )}
       
      </Routes>
  </Router>
  );
}

export default App;
