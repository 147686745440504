import React from 'react'
import PdfViewer from '../Reusable/PdfViewer/PdfViewer'

const ReportPdf = () => {
  return (
    <div>
      <PdfViewer/>
    </div>
  )
}

export default ReportPdf
