
import React from 'react';
import './Tooltip.css';

function Tooltip({ text, children,itemWidth,transform,top}) {
  const trStyle = {
    transform: transform,
    top:top,
    width:itemWidth
  };
  return (
    <div className="tooltip-container">
      {children}
      <div style={trStyle} className="tooltip-text">{text}</div>
    </div>
  );
}

export default Tooltip;
