import React,{useState,useEffect} from 'react';
import './PdfViewer.scss';
import report from '../../../../assets/docGold.png';
import { GoDotFill } from "react-icons/go";
import { AiOutlineLink } from "react-icons/ai";
import { RxDownload } from "react-icons/rx";
import { FiBookmark } from "react-icons/fi";
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import DashboardLoader from '../DashboardLoader/DashboardLoader';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import closeIcon from '../../../../assets/greys.png';
import BaseButton from '../../../Onboarding/Reusables/BaseButton/BaseButton';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 448,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
    padding:"16px 32px 32px",
    // overflowY:"auto"
   
  };

const PdfViewer = () => {

   
    const userData = useSelector(state => state.userLogin.userInfo);
    const token = userData.token;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const paramData = useParams()
    const [docData,setDocData] = useState([]);
   const [loading, setLoading] = useState(true);
   const [open, setOpen] = useState(false); 
   const [rejection_reason, setRejectionReason] = useState('');
   const viewOnlyUrl = `${docData.url}#toolbar=0&view=FitH`;

    const getDocuments = () => {

        try{ 
      
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
            };
        
          axios.get(`${baseUrl}documents/contributor/${paramData.id}`, config)
            .then((response) => {
              
              console.log(response.data);
             setDocData(response.data.data)
                setLoading(false);
            
            })
            .catch((error) => {
           
              if (error.response) {
                if (error.response.data.message === "Please login again.") {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    alert(error.response.data.message);
                }
              } else if (error.request) {
                  alert("Check your connection. Unable to reach the server.");
              } else {
                  alert("An error occurred. Please try again later.");
              }
            });
          }
          catch(error){
            if (error.response) {
              if (error.response.data.message === "Please login again.") {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
              } else {
                  alert(error.response.data.message);
              }
          } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
          } else {
              alert("An error occurred. Please try again later.");
          }
          }
    };


    //Reject document
    const rejectDocument = () => {

        let body={
            rejection_reason:rejection_reason
        }

        try{ 
      
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
            };
        
          axios.patch(`${baseUrl}documents/contributor/${paramData.id}/reject`, body, config)
            .then((response) => {
              
              console.log(response.data);
              alert(response.data.message)
              getDocuments()
            
            })
            .catch((error) => {
           
              if (error.response) {
                if (error.response.data.message === "Please login again.") {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    alert(error.response.data.message);
                }
              } else if (error.request) {
                  alert("Check your connection. Unable to reach the server.");
              } else {
                  alert("An error occurred. Please try again later.");
              }
            });
          }
          catch(error){
            if (error.response) {
              if (error.response.data.message === "Please login again.") {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
              } else {
                  alert(error.response.data.message);
              }
          } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
          } else {
              alert("An error occurred. Please try again later.");
          }
          }
    };

    //Approve document
    const approveDocument = () => {
   

        try{ 
      
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                   Authorization: `Bearer ${token}`,
                },
            };
        
          axios.patch(`${baseUrl}documents/contributor/${paramData.id}/merge`,{}, config)
            .then((response) => {
              
              console.log(response.data);
              alert(response.data.message)
              getDocuments()
            
            })
            .catch((error) => {
           
              if (error.response) {
                if (error.response.data.message === "Please login again.") {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    alert(error.response.data.message);
                }
              } else if (error.request) {
                  alert("Check your connection. Unable to reach the server.");
              } else {
                  alert("An error occurred. Please try again later.");
              }
            });
          }
          catch(error){
            if (error.response) {
              if (error.response.data.message === "Please login again.") {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
              } else {
                  alert(error.response.data.message);
              }
          } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
          } else {
              alert("An error occurred. Please try again later.");
          }
          }
    };

    //Sudspend document
    const suspendDocument = () => {
   

        try{ 
      
       
          const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
            };
        
          axios.patch(`${baseUrl}documents/contributor/${paramData.id}/active`,{}, config)
            .then((response) => {
              
              console.log(response.data);
              alert(response.data.message);
              getDocuments()
            
            })
            .catch((error) => {
           
              if (error.response) {
                if (error.response.data.message === "Please login again.") {
                    localStorage.clear(); // Clear local storage
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    alert(error.response.data.message);
                }
              } else if (error.request) {
                  alert("Check your connection. Unable to reach the server.");
              } else {
                  alert("An error occurred. Please try again later.");
              }
            });
          }
          catch(error){
            if (error.response) {
              if (error.response.data.message === "Please login again.") {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
              } else {
                  alert(error.response.data.message);
              }
          } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
          } else {
              alert("An error occurred. Please try again later.");
          }
          }
    };
      
        useEffect(()=>{   
            getDocuments()
        // eslint-disable-next-line
    },[]);

    function formatDateLong(isoString) {
        const date = new Date(isoString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const handleRejectionReasonChange = (e) => {
        const words = e.target.value.split(/\s+/);
        if (words.length <= 75) {
            setRejectionReason(e.target.value);
        }
    };
   
    return (
        
        loading ? <DashboardLoader/> :
        <>
        <div className='pdf-backgd'>

            <div className='pdf-main-cont'>

                <div className='pdf-cont-header'>

                    <div className='pdf-header-left'>

                        <div>
                         <img src={report} alt="" />
                        </div>

                        <div>
                            <h4>{docData.title}</h4>
                            <p className='pdf-left-tag'>
                                <div className='pdf-left-tag-views'>
                                    <GoDotFill
                                    color='#04802E'
                                    />
                                    <h3> {docData.total_views} Views</h3>
                                </div>
                               
                               
                                <p
                                    className='admin-col-status'
                                    style={{
                                    color:
                                        docData.status === "rejected"
                                        ? "#9E0A05"
                                        : docData.status === "pending_review"
                                        ? "#8B5904"
                                        : docData.status === "withdrawn"
                                        ? "#4B4F57"
                                        : "#036B26",

                                        backgroundColor:
                                        docData.status === "rejected"
                                        ? "#FBEAE9"
                                        : docData.status === "pending_review"
                                        ? "#FEF0D8"
                                        : docData.status === "withdrawn"
                                        ? "#F3F2EF"
                                        : "#E7F6EC",
                                    fontWeight:500
                                    }}
                                    >
                                    {docData.status}
                                </p>

                                <div className='pdf-left-tag-tags'>
                                    <GoDotFill
                                    color='#865503'
                                    />
                                    <h3>{docData.industries.map(industry => industry.name).join(', ')}</h3>
                                </div>
                    

                                <p>Submitted: {formatDateLong(docData.publication_date)}</p>
                                {docData.merged_date !== null &&
                                <p>Merged: {formatDateLong(docData.merged_date)}</p>
                                }
                            </p>
                        </div>

                    </div>

                    <div className='pdf-header-btn'>

                        <div >
                            <h4
                            onClick={()=>window.location.href=`/dashboard/contributions/report/${docData.id}`}
                            className='pdf-header-close-btn'
                            >Close Preview</h4>
                        </div>
                                
                        {docData.status === "merged" &&
                        <div 
                        onClick={()=>suspendDocument()}
                        className='pdf-header-report-btn'>
                          
                            <h4>Suspend</h4>
                        </div>
                       }

                        {docData.status === "pending_review" &&
                        <>
                        <div
                        onClick={()=>setOpen(true)}
                         className='pdf-header-report-btn'>
                          
                            <h4
                            >Reject</h4>
                        </div>

                        <div 
                        onClick={()=>approveDocument()}
                        className='pdf-report-btn-green'>
                          
                            <h4>Approve</h4>
                        </div>
                        </>
                      }

                        {docData.status === "rejected" &&
                        
                       

                        <div 
                        onClick={()=>approveDocument()}
                        className='pdf-report-btn-green'>
                          
                            <h4>Approve</h4>
                        </div>
                        
                      }
                    </div>

                </div>


                <section className='pdf-section' >

                    <div>

                    </div>

                    <div className='pdf-iframe-cont'>
                    
                    <iframe
                    src={viewOnlyUrl}
                    title="PDF Viewer"
                    style={{ width: '100%', height: '100vh', border: 'none',padding:"40px 0px" }}
                    >

                    </iframe>
                    
                    </div>

                    <div>

                        <div className='pdf-icons'>
                            <AiOutlineLink
                            size={18}
                            />
                        </div>

                        <div className='pdf-icons'>
                            <RxDownload
                            size={18}
                            />
                        </div>

                        <div className='pdf-icons'>
                            <FiBookmark
                            size={18}
                            />
                        </div>

                    </div>
                </section>
                
            </div>
     
        </div>
        

        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style} >

            <div className='pdf-modal-cont'>
                
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img onClick={() => setOpen(false)} src={closeIcon} alt="" />
                </div>

                <h4>Reason for Rejection</h4>

                <h5>Why is this contribution rejected</h5>

                <textarea 
                name="" 
                id=""
                maxLength={75}
                value={rejection_reason}
                onChange={handleRejectionReasonChange}
                >


                </textarea>
                <p>Please keep this below 75 words.</p>

                <BaseButton
                
                 title={"Confirm Rejection"}
                 width={"100%"}
                 height={"44px"}
                 padding={"0.5em 1em"}
                 onClick={()=>rejectDocument()}
                />
                
                
                  
               

          
            </div>

        </Box>
        </Modal>
        </>


    );
};

export default PdfViewer;
