import React from 'react'
import AdminSidebar from '../Reusable/AdminSidebar/AdminSidebar';
import HomeNav from './HomeNav/HomeNav'
import './Home.scss';
import StatsCont from './StatsCont/StatsCont';
import DateChart from './LineChart/LineChart';
import DonutChart from './DonutChart/DonutChart';
import TreemapChart from './TreeMap/TreeMap';
import RecentActivity from './RecentActivity/RecentActivity';

const HomeContributor = () => {
  
    const labelValues =["Sole Consumers", "Hybrid"]
    const labelValue2 =["Datasets", "Infographics","Documents"]
    const data1 = [60, 40]
    const data2 = [50, 25, 25];
    const backColor1 = ["#007bff", "#000000"];
    const backColor2 = ["#F3A218","#0F973D", "#007bff"];

    return (
      <div className='admin_home_cont'>
        <AdminSidebar/>
  
        <section className='admin_home_side'>
          <HomeNav
          header={"Contributor"}
          />
          <StatsCont
            box1={"Contributors"}
            box2 = {"Payouts"}
            box3={"Contributions"}
          />
  
          <div className='admin_chart_one'>
  
            <div className='admin_charts_box'>
              <DateChart
              header={"Contributions by Date"}
              />
              <DonutChart
                header={"Contributions by Type"}
              dataSet = {data1}
              labelValues={labelValues}
              backGr={backColor1}
              content={"Total Users"}
              values={"230,032"}
              />
            </div>

            <div className='admin_charts_box'>
             
              <DonutChart
              header={"Contributions by Resources"}
              dataSet = {data2}
              labelValues={labelValue2}
              backGr={backColor2}
              values={"300"}
              content={"Contributions"}
              />
              <DateChart
               header={"Payouts by Date"}
              />
            </div>
  
            <TreemapChart
                header={"Contributor by location"}
            />
          
            
          </div>
  
          <RecentActivity
          header={"Pending Approvals"}
          />
  
        </section>
      </div>
    )
  }
export default HomeContributor
