import React from 'react';
import './NoResult.scss';
import imgOne from '../../../../assets/noresult.png'
const NoResult = ({height}) => {

  const useStyle={
    height:height
  }
  return (

    <div  className='no_result_main'>
         <div style={useStyle} className='no_result_cont'>
            <div>
                <img src={imgOne} alt="no result icon" />
            </div>
             <p>No Results Found</p>
        </div>
    </div>
   
  )
}

export default NoResult
