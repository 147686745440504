import React from 'react';
import './Navbar.scss';
import arrow from '../../../../assets/backArr.png';


const Navbar = ({
  page,
  pageicon,
  mainPage,
  mainPageIcon,
  subPage,
  subPageIcon,
    url
}) => {

  return (
    <div className='navbar_cont'>

            <div>
                <img
                onClick={()=>window.location.href=`${url}`}
                src={arrow} alt="" />
            </div>

            <div>

            </div>

            <div className='nav_links'>
                <div>
                    <div>
                    <img src={mainPageIcon} alt="" />
                    </div>
                   
                    <h4>{mainPage}</h4>
                </div>
                
               

                {subPage && 
                <>
                <h4 style={{color:'#9CA0A5'}}>/</h4>
                <div>
                    <div>
                        <img src={subPageIcon} alt="" />
                    </div>
                    
                    <h3 style={{color:"#007EFF"}}>{subPage}</h3>
                </div>
                </>
                }
                <h4 style={{color:'#9CA0A5'}}>/</h4>

                <div>
                    <div>
                    <img src={pageicon} alt="" />
                    </div>
                    
                    <h3>{page}</h3>
                </div>
            
            </div>
    </div>
  )
}

export default Navbar
