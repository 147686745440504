import React from 'react'
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import img1 from '../../../../assets/reporticon.png';
import "./RecentActivity.scss"
const RecentActivity = ({header}) => {

    const data=  [
        {
            icon:img1,
            header:"Contributor Review - Ololade Asake (User ID: 2930302)",
            time:"12 mins ago"
        },
        {
            icon:img1,
            header:"Contributor Review - Ololade Asake (User ID: 2930302)",
            time:"12 mins ago"
        },
        {
            icon:img1,
            header:"Contributor Review - Ololade Asake (User ID: 2930302)",
            time:"12 mins ago"
        },
        {
            icon:img1,
            header:"Contributor Review - Ololade Asake (User ID: 2930302)",
            time:"12 mins ago"
        },
        {
            icon:img1,
            header:"Contributor Review - Ololade Asake (User ID: 2930302)",
            time:"12 mins ago"
        },
        {
            icon:img1,
            header:"Contributor Review - Ololade Asake (User ID: 2930302)",
            time:"12 mins ago"
        }
    ]
  return (
    <div className='recent-activity-main'>
      <div className='recent-activity-body' >

        <div className='recent-activity-header'>
            <h4>{header}</h4>
            <div>
                <h4>See all</h4>
                <IoIosArrowRoundForward
                size={30}
                color=''
                />
            </div>
        </div>
        {data.map((item,index)=>(
        <div key={index} className='recent-activity-content'>
           
                
            <div  className='recent-activity-img'>
                <div>
                <img src={item.icon} alt="" />
                </div>
                
                 <div>
                     <h4>{item.header}</h4>
                     <p>{item.time}</p>
                 </div>
             </div>
 
             <IoIosArrowForward
             size={20}
             color='#9CA0A5'
             />
             
            
           
        </div>
        ))}

      </div>
    </div>
  )
}

export default RecentActivity
