
import React, { useState, useEffect, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import './DonutChart.scss'
ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({labelValues,dataSet,backGr,header,content,values}) => {

  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { offsetWidth } = containerRef.current;
        const { offsetHeight } = containerRef.current;
        setDimensions({ width: offsetWidth, height: offsetHeight });
      }
    };

    // Initialize dimensions
    updateDimensions();

    // Update dimensions on window resize
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const data = {
    labels: labelValues,
    datasets: [
      {
        data: dataSet,
        backgroundColor: backGr, // Blue and Black colors
        hoverBackgroundColor: ["#0056b3", "#333333",], // Optional hover effect
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: "65%", 
    maintainAspectRatio: false, // Ensures the chart takes the container's dimensions
    layout: {
      padding: 0, // Removes any extra padding around the chart
    },
    plugins: {
      legend: {
        
        display: true,
        position: "right", // Keeps the legend at the bottom
        // align: "end", // Aligns legend to the end
        labels: {
          usePointStyle: true, // Makes legend icons circular
          pointStyle: "circle", // Ensures round points
          boxWidth: 6, // Small circle size
          boxHeight: 6, // Small circle size
          padding: 15, // Space between legend items
          font: {
            size: 12, // Font size of legend text
            weight: "400", // Light weight text
            family:"Inter"
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.label}: ${tooltipItem.raw}%`, // Tooltip format
        },
      },
    },
  };

  return (
    <div 
   
    className="donut_main">
      <h4 className="chart_header_donut">{header}</h4>

      <div
       ref={containerRef}
      // style={{height:"284px"}}
      >

          <Doughnut 
          width={dimensions.width}
          height={dimensions.height} 
          data={data} 
          options={options} />

          
      </div>
         <div className="donut_chart">
          <strong>{values}</strong>
          <p style={{ fontSize: "12px", color: "#666"}}>{content}</p>
        </div>
     
    </div>
  );
};

export default DonutChart;
