import React,{useContext,useState} from 'react';
import './CreateDocument.scss';
import arrow from '../../../assets/backicon.svg'
import { multiStepContext } from '../Reusable/StepContext/StepContext';
import DocAcknowledge from './DocAcknowledge/DocAcknowledge';
import CreateDocSideBar from './CreateDocSidebar/CreateDocSideBar';
import DocumentSummary from './DocumentSummary/DocumentSummary'
import axios from 'axios';
import { useSelector } from 'react-redux';

const CreateDocument = () => {

  
  const { currentstep,setStep } = useContext(multiStepContext);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const [file, setFile] = useState(null);
  const [selectedKeyId, setSelectedKeyId] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [doctype, setDocType] =  useState('') ;
  const [howDoc, setHowDoc] =  useState('');
  const [docSource, setDocSource] = useState('');
  const [docTitle,setDocTitle] = useState('');
  const [description,setDescription]=useState('');
  const [author,setAuthor]= useState('');
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLang, setSelectedLang]= useState('')
  const [loading, setLoading] = useState(false);

  function handleDocData (docData) {
    setDocSource(docData.docSource)
    setAuthor(docData.author)
    setFile(docData.file)
    setDescription(docData.description)
    setSelectedDate(docData.selectedDate)
    setDocTitle(docData.docTitle)
    setSelectedKeyId(docData.selectedKeyId)
    setHowDoc(docData.howDoc)
    setDocType(docData.doctype)
     setSelectedLocation(docData.selectedLocation)
     setSelectedLang(docData.selectedLang)
  }

  function dateFormat(dateStr) {
    // Split the input string by the delimiter "-"
    const [day, month, year] = dateStr.split("/");
  
    // Return the date in the desired format
    return `${year}-${month}-${day}`;
  }
    const createDocument = () => {
       setLoading(true);
        const formData = new FormData();
        formData.append("file", file); // Assuming `file` is your uploaded PDF file
        formData.append("title", docTitle);
        formData.append("description", description);
        formData.append("author", author);
        formData.append("source", docSource);
        formData.append("access", howDoc);
        formData.append("document_type", doctype);
        formData.append("language", selectedLang); // Replace with dynamic language if needed
        selectedKeyId.forEach((item) => {formData.append('industry', item)}) 
        selectedLocation.countries.forEach((item) => {formData.append('location', item)}) 
        
        if (selectedLocation.regions.length > 1) {
          formData.append('region', 'multiple');
      } else {
          selectedLocation.regions.forEach((item) => {
              formData.append('region', item);
          });
      } 
        formData.append("publication_date", dateFormat(selectedDate)); // Ensure this is formatted correctly
  
          const config = {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`, // Authorization header
              },
          };
      
        axios.post(`${baseUrl}documents`, formData, config)
          .then((response) => {
            console.log("Document created successfully:", response.data);
            alert("Document created successfully!");
            setLoading(false);
            window.location.href = '/dashboard/contributions';
          })
          .catch((error) => {
            setLoading(false);
            if (error.response) {
              if (error.response.data.message === "Please login again.") {
                  localStorage.clear(); // Clear local storage
                  window.location.href = '/login'; // Redirect to login page
              } else {
                  alert(error.response.data.message);
              }
          } else if (error.request) {
              alert("Check your connection. Unable to reach the server.");
          } else {
              alert("An error occurred. Please try again later.");
          }
          });
      };

  return (
    <div>

        <div className='create-doc-banner'>
           { currentstep === 1 && 
            <img 
            onClick={()=> window.location.href="/dashboard/contributions"}
            src={arrow} alt="" />
           }
           { currentstep === 2 && 
            <img 
            onClick={()=> setStep(1)}
            src={arrow} alt="" />
           }
        </div>

        <div className='create-doc-body'>
          {currentstep === 1 && 
          
          <DocumentSummary
          onSelect={handleDocData}
          />
          
          }
          {currentstep === 2 && 
          <DocAcknowledge
           callSummaryFunction={createDocument}
           loadingFunction={loading}
          />}
         
          <CreateDocSideBar
          
          />
        </div>

    </div>
  )
}

export default CreateDocument
