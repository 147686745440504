import React,{useState} from 'react';
import { FaPlus } from "react-icons/fa6";
import './ContributionHeader.scss'
import { IoSearch } from "react-icons/io5";
import { BsFilter } from "react-icons/bs";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import closeIcon from '../../../../assets/greys.png';
import paper1 from '../../../../assets/newspaperTwo.png';
import paper2 from '../../../../assets/newspaperTwoUn.png';
import check from '../../../../assets/Outer Circle.png';
import checked from '../../../../assets/checked.svg';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 560,
    height: 705,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    outline: "none",
    padding:"16px 32px 32px",
    // overflowY:"auto"
   
  };

const ContributionHeader = () => {
    const [selectedItem,setSelectedItem] = useState("doc");
    const [open,setOpen] = useState(false)

  return (

    <div className='contb-header-cont'>

        <div className='contb-header-box'>

            <h4 className='contb-header'> 
                All Contributions
            </h4>


            <div>
                <div 
                    className='contb-header-search'
                >
                    <IoSearch
                    color='#77787C'
                    />
                    <input 
                    placeholder='Search'
                    type="text" />
                </div>

                <button 
                    onClick={()=>setOpen(true)}
                    className='new-contrbtn'>
                    <FaPlus
                    color='#FFFFFF'
                    size={15}
                    />
                <p> New Contribution</p>
           
                </button>
            </div>

        </div>


        <div className='contb-type-box'>
            <div className='contb-type'>
                <h4
                  onClick={()=>setSelectedItem("data")}
                  className={selectedItem === "data" ? "contb-type-active":"contb-type-inactive"}
                >Data</h4>
                <h4
                  onClick={()=>setSelectedItem("doc")}
                  className={selectedItem === "doc" ? "contb-type-active":"contb-type-inactive"}
                >Documents</h4>
                <h4 
                onClick={()=>setSelectedItem("map")}
                className={selectedItem === "map" ? "contb-type-active":"contb-type-inactive"}
                >
                    Maps and Dashboards
                </h4>
            </div>

            <div className='contb-filter'>
                <BsFilter
                
                color='#77787C'
                size={20}
                />
                <h4>Filter</h4>
            </div>
        </div>

        <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} overflow-x >

                <div style={{textAlign:"right"}}>
                    <img 
                    onClick={()=>setOpen(false)}
                    src={closeIcon} alt="" />
                </div>
                

                <div className='cont_modal_header'>

                    <h2>Add New Contribution</h2>
                    <p>Contributors can add data and reports to the Sonaroid platform. You get paid when your contributions are viewed by others.</p>

                </div>

                <h3 className='cont_modal_sub'> What type of resource are you adding? </h3>

                <div className='cont_modal_content'>

                    <div>
                        <div>
                         { selectedItem === "data" ? 
                            <div>
                                <img src={checked} alt="" />
                            </div>
                             
                            :
                            <div>
                                 <img 
                                onClick={()=>setSelectedItem("data")}
                                src={check} alt="icon" 
                                />
                            </div>
                           
                         }
                        </div>
                        

                        <div>
                            <h4>Add New Data Table</h4>
                            <p>Add a new table by importing CSV or creating manually.</p>
                        </div>
                    </div>
                    

                    <img
                    style={selectedItem === "data" ? {border:'1px solid blue',borderRadius :"4px"} :{} }
                    src={paper1} alt="paperimg" />
                </div>

                <div className='cont_modal_content'>

                    <div>
                        { selectedItem === "table" ? 
                             <div>
                                <img src={checked} alt="" />
                             </div>
                            :
                            <div>
                                <img 
                            onClick={()=>setSelectedItem("table")}
                            src={check} alt="icon" 
                            />
                            </div>
                         }

                        <div>
                            <h4>Update Existing Table</h4>
                            <p>Add new columns or rows to an existing table on Sonaroid.</p>
                        </div>
                    </div>

                   

                    <img
                    style={selectedItem === "table" ?  {border:'1px solid blue',borderRadius :"4px"} :{} }
                    src={paper1} alt="paperimg" />
                </div>

                <div className='cont_modal_content'>

                    <div>

                        { selectedItem === "doc" ? 
                            <div>
                              <img src={checked} alt="" />
                           </div>
                          :
                          <div>
                              <img 
                                onClick={()=>setSelectedItem("doc")}
                                src={check} alt="icon" 
                                />
                          </div>
                         }
                        

                        <div>
                            <h4>Upload Reports</h4>
                            <p> Attach a pdf document</p>
                        </div>
                    </div>

                   

                    <img  
                    style={selectedItem === "doc" ?  {border:'1px solid blue',borderRadius :"4px"}  :{} }
                    src={paper2} alt="paperimg" />
                </div>

                <button 
                onClick={()=>  window.location.href="/admin/createdoc"}
                className='cont_modal_button'>
                    Proceed
                </button>
              </Box>
        </Modal>
      
    </div>
  )
}

export default ContributionHeader
