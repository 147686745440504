import React, { useState, useEffect, useRef } from "react";
import { Treemap, Tooltip } from "recharts";
import './TreeMap.scss';


const data = [
  { name: "Nigeria", size: 50000, color: "#134AB9" },
  { name: "South Africa", size: 36400, color: "#3398FF" },
  { name: "Egypt", size: 36580, color: "#C3E1FF" },
  { name: "Kenya", size: 24491, color: "#134AB9" },
  { name: "Namibia", size: 24491,  color: "#3398FF" },
  { name: "France", size: 24491, color: "#C3E1FF" },
  { name: "Russia", size: 24491, color: "#134AB9" },
  { name: "UK", size: 24491, color: "#3398FF" },
  { name: "Angola", size: 24491, color: "#C3E1FF" },
  { name: "Others", size: 24491, color: "#134AB9" },
];

const CustomTooltip = ({ payload }) => {
  if (!payload || payload.length === 0) return null;

  const { name, size } = payload[0].payload;

  return (
    <div style={{  border: "1px solid #ccc", padding: 10 }}>
      <strong>{name}</strong>
      <p>Users: {size.toLocaleString()}</p>
    </div>
  );
};



const TreemapChart = ({header}) => {

  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 300 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { offsetWidth } = containerRef.current;
        setDimensions({ width: offsetWidth, height: 300 });
      }
    };

    // Initialize dimensions
    updateDimensions();

    // Update dimensions on window resize
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div
    className="tree-map-main"
    >


    <div 
    ref={containerRef}
    className="tree-map-cont"
   
    >
      <h4 className="chart_header" style={{fontSize:"1.125rem"}}>{header}</h4>
      <Treemap
        width={dimensions.width}
        height={dimensions.height - 40} 
        data={data}
        dataKey="size"
        // stroke="#fff"
        content={({ x, y, width, height, name,size, color }) => (
          <g>
            <rect
              x={x}
              y={y}
              width={width}
              height={height}
              style={{ fill: color, stroke: "#fff", strokeWidth: 2 }}
            />
            <text
          
              x={x + width / 2}
              y={y + height / 3}
              fill="#FFFFFF"
              fontSize={12}
              textAnchor="middle"
              alignmentBaseline="middle"
            >
              {name}
             
            </text>
            <text
          
              x={x + width / 2}
              y={y + height / 2}
              fill="#FFFFFF"
              fontSize={12}
              textAnchor="middle"
              alignmentBaseline="middle"
            >
             
              {size}
            </text>
          </g>
        )}
      />
      <Tooltip content={<CustomTooltip />} />
    </div>
    </div>
  );
};

export default TreemapChart;
