import React from 'react';
import SyncLoader from "react-spinners/SyncLoader";
import './DashboardLoader.scss'
const DashboardLoader = () => {
  return (
    <div className='dash-loader'>
        <div>
        <SyncLoader 
            color='#007EFF'
        />
        </div>
    </div>
  )
}

export default DashboardLoader
