import React from 'react';
import logo from '../../../../assets/sideLogoAdmin.png';
import './AdminSidebar.scss';
import admin from '../../../../assets/sidebarAdmin.png'
import overview from '../../../../assets/overviewIcon.png';
import fileIcon from '../../../../assets/file-download.png';
import userIcon from '../../../../assets/user-group.png';
import newsIcon from '../../../../assets/loudspeaker.png';
import subIcon from '../../../../assets/coin-stack.png';
import { FiLogOut } from "react-icons/fi";
import { Link, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";


const AdminSidebar = () => {
  
  const userData = useSelector(state => state.userLogin.userInfo);
  // const token = userData.token
  // const userEmail = userData.user.email;
  
  const firstName = userData.user.first_name;
  // const lastName = userData.user.last_name;
  // const fullName = firstName + " " + lastName;

    const { pathname } = useLocation();
   
    const sideBarLinks = [
        {
          name: "Overview",
          path: "/overview/home",
          icon1: overview ,
          
        },
        {
          name: "Users",
          path: "/#",
          icon1: userIcon,
         
        },
        {
          name: "Contributions",
          path: "/dashboard/contributions",
          icon1: fileIcon,
          
        },
        {
          name: "News",
          path: "/dashboard/news",
          icon1: newsIcon,
        
        },
        {
          name: "Subscriptions",
          path: "/#",
          icon1: subIcon,
         
        },
        
      ];
   

  const handleLogout=()=>{

    localStorage.clear();
    sessionStorage.clear();
    // navigate('/', { replace: true }); 
    window.location = "/";
  }
  return (
    <div className='admin-sdb-cont'>
      <div>
      <div className='sidebar-content'>

      
        <div className='admin-sdb-cont-logo'>
          <img src={logo} alt="" />
        </div>

        {sideBarLinks.map((item, i) => (
          <Link style={{textDecoration:"none"}}  to={item.path} key={i}>
       
          <div className={pathname.startsWith(item.path) ? 'admin-sdb-cont-pg alt':'admin-sdb-cont-pg'}>
              <div>
              <img 
              src={item.icon1}
              alt={item.name}
              />
              </div>
              
              <h4>{item.name}</h4>
          </div>
          </Link>
        ))}
    </div>

    <div style={{marginTop:'32px'}}className={pathname==='/admin' ? 'admin-sdb-cont-pg alt':'admin-sdb-cont-pg'}>
      <img src={admin} alt="" />
      <h4>Manage Admins</h4>
    </div>

  </div>

       <div className='admin-sdb-btm'>

    
       <div className='admin-sdb-logout'>
            <div>
              <div className='admin-sdb-logout-img' >
                <img src="https://images.pexels.com/photos/709143/pexels-photo-709143.jpeg?auto=compress&cs=tinysrgb&w=600" alt="profile" />
              </div>

              <div className='admin-sdb-logout-mail'>
                <h4>{firstName}</h4>
                {/* <p>{userEmail}</p> */}
              </div>
            </div>
            
            
            <FiLogOut
            color='#FFFFFF'
            size={20}
            onClick={()=>handleLogout()}
            />
           
       </div>
       </div>
      
    </div>
  )
}

export default AdminSidebar
