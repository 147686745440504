import React,{useEffect,useState} from 'react'
import Navbar from '../Reusable/Navbar/Navbar'
import ReportContentView from '../Reusable/ReportContentView/ReportContentView'
import AdminSidebar from '../Reusable/AdminSidebar/AdminSidebar'
import contri from '../../../assets/contriIconBlue.png';
import docBlue from '../../../assets/docIconBlue.png';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import DashboardLoader from '../Reusable/DashboardLoader/DashboardLoader';

const ViewReport = () => {

  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const paramData = useParams()
  const [docData,setDocData] = useState([]);
 const [loading, setLoading] = useState(true);

  const getDocuments = () => {

  try{ 

 
    const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Authorization header
          },
      };
  
    axios.get(`${baseUrl}documents/contributor/${paramData.id}`, config)
      .then((response) => {
        
        console.log(response.data);
       setDocData(response.data.data)
      setLoading(false);
      
      })
      .catch((error) => {
     
        if (error.response) {
          if (error.response.data.message === "Please login again.") {
              localStorage.clear(); // Clear local storage
              window.location.href = '/login'; // Redirect to login page
          } else {
              alert(error.response.data.message);
          }
        } else if (error.request) {
            alert("Check your connection. Unable to reach the server.");
        } else {
            alert("An error occurred. Please try again later.");
        }
      });
    }
    catch(error){
      if (error.response) {
        if (error.response.data.message === "Please login again.") {
            localStorage.clear(); // Clear local storage
            window.location.href = '/login'; // Redirect to login page
        } else {
            alert(error.response.data.message);
        }
    } else if (error.request) {
        alert("Check your connection. Unable to reach the server.");
    } else {
        alert("An error occurred. Please try again later.");
    }
    }
  };

   useEffect(()=>{   
        getDocuments()
      // eslint-disable-next-line
      },[]);
  return (
    <div className='dashboard-main'>
    <div>
      <AdminSidebar/>
    </div>
     
     <section>
      <Navbar
        mainPage = {"Contributions"}
        mainPageIcon={contri}
        page={"Solar and meteorological ground measurements from Senegal"}
        pageicon={""}
        subPage={"Documents"}
        subPageIcon={docBlue}
        url={"/dashboard/contributions"}
      />

      {loading ? 
      <DashboardLoader/> :
      <ReportContentView
      docData={docData}
      />
    }
      </section>
    </div>
  )
}

export default ViewReport
