import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import "@fontsource/inter/300.css"; 
import "@fontsource/inter/400.css"; // 
import "@fontsource/inter/500.css"; //
import "@fontsource/inter/600.css"; //


import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import StepContext from './components/AdminDashboard/Reusable/StepContext/StepContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
    <StepContext>
      <App />
      </StepContext>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
