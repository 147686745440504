import React,{useState,useEffect,useRef} from 'react';
import Navbar from '../Reusable/Navbar/Navbar';
import AdminSidebar from '../Reusable/AdminSidebar/AdminSidebar';
import NewsPageHeader from '../Reusable/NewsPageHeader/NewsPageHeader';
import NewsPage from '../Reusable/NewsPage/NewsPage';
import NoResult from '../Reusable/NoResult/NoResult';
import DashboardLoader from '../Reusable/DashboardLoader/DashboardLoader';
import axios from 'axios';
import { useSelector } from "react-redux";

const AdminNewsBookmark = () => {

  const isFirstRender = useRef(true);
  const [loading, setLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(state => state.userLogin.userInfo);
  const token = userData.token;
  const [resultNews, setResultNews] =  useState([])
  const [totalFilter, setTotalFilter]= useState(null);
  const [currPgRes, setCurrPgRes] = useState(1);
  const [filters, setFilters] = useState({
    language: null,
    search:null,
    keywords: [],
    startDate: null,
    endDate: null,
    is_bookmarked:true
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; 
    }
    
    filterNewsToDisplay(currPgRes);
    // eslint-disable-next-line
  }, [filters, currPgRes]);

  function convertDateFormat(dateString) {
  
    const [day, month, year] = dateString.split("/");
  
    return `${year}-${month}-${day}`;
  }
 
 




  


  const getBookmarks = async () => {
  
    
    const queryParams = {};
    
    queryParams.is_bookmarked = true;
    
    queryParams.limit = 21;

    const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');


    try {

      setLoading(true)
        const config = {
            headers: {
                
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            
            }
        }
        const response = await axios.get(`${baseUrl}news/?${queryString}`, config);
        console.log(response)
     
        
        setResultNews(response.data.data)
        setTotalFilter(response.data.meta.totalItems)
        setLoading(false)
    }

    catch (error) {
      setLoading(false)
        console.log("error", error);
        if (error.response) {
          if (error.response.data.message === "Please login again.") {
              localStorage.clear(); // Clear local storage
              window.location.href = '/login'; // Redirect to login page
          } else {
              alert(error.response.data.message);
          }
      } else if (error.request) {
          alert("Check your connection. Unable to reach the server.");
      } else {
          alert("An error occurred. Please try again later.");
      }
        
    }
  }


  const filterNewsToDisplay = async (page) => {
  
    
    const queryParams = {};
    
    if(page){
      queryParams.page = page;
    }
    
    if (filters.language) {
      queryParams['language[]'] = filters.language;
    }
   
    queryParams.is_bookmarked = true;
    
    if (filters.search) {
      queryParams.search = filters.search;
    }
    if (filters.keywords.length > 0) {
      filters.keywords.forEach((item, index) => {
          queryParams[`industry[${index}]`] = item;
      });
  }
    
    if (filters.startDate) {
      queryParams.start_date = convertDateFormat(filters.startDate);
    }

    if (filters.endDate) {
      queryParams.end_date = convertDateFormat(filters.endDate);
    }


    queryParams.limit = 21;

    const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');


    try {

     
        const config = {
            headers: {
                
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            
            }
        }
        const response = await axios.get(`${baseUrl}news/?${queryString}`, config);
        console.log(response)
     
        
        setResultNews(response.data.data)
        setTotalFilter(response.data.meta.totalItems)
       
    }

    catch (error) {
     
        console.log("error", error);
        if (error.response) {
          if (error.response.data.message === "Please login again.") {
              localStorage.clear(); // Clear local storage
              window.location.href = '/login'; // Redirect to login page
          } else {
              alert(error.response.data.message);
          }
      } else if (error.request) {
          alert("Check your connection. Unable to reach the server.");
      } else {
          alert("An error occurred. Please try again later.");
      }
        
    }
  }

  


  useEffect(()=>{   
    getBookmarks()
  // eslint-disable-next-line
  },[]);

    
  const handlePageChangeRes = (page) => {
    setCurrPgRes(page);
   
  };

   const handleLanguageChange = (language) => {
    setFilters((prevFilters) => ({ ...prevFilters, language })); 
  };

  const handleTagsChange = (keywords) => {
    setFilters((prevFilters) => ({ ...prevFilters, keywords }));
  };

  const handleDateChange = (startDate, endDate) => {
    setFilters((prevFilters) => ({ ...prevFilters, startDate, endDate }));
  };


  const handleLanguageRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, language: null }));
  
  };

  const handleTagsRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, keywords:[]}));
  
  };

  const handleDateRemove = () => {
    setFilters((prevFilters) => ({ ...prevFilters, startDate: null,endDate:null }));
  
  };

  const handleSearchChange =(search)=>{
    setFilters((prevFilters) => ({ ...prevFilters, search }));
  }

  

  
  return (

    <>
    {loading ? (

      <div>
      <DashboardLoader/>
      </div>
    ):(

    <div className='dashboard-main'>
        <div>
          <AdminSidebar/>
        </div>
       
      <section>
        <Navbar/>
     
       
        
            {resultNews.length === 0 ?(

              <>
              <NewsPageHeader
              onLanguageChange={handleLanguageChange}
              onSearchChange={handleSearchChange}
              onTagsChange={handleTagsChange}
              onDateChange={handleDateChange}
              onLangRemove={handleLanguageRemove}
              onTagsRemove={handleTagsRemove}
              onDateRemove={handleDateRemove}
              />

              <NoResult
                height="739px"
              /> 
              </>
            ):(
            <>
              <NewsPageHeader
              onLanguageChange={handleLanguageChange}
              onSearchChange={handleSearchChange}
              onLangRemove={handleLanguageRemove}
              onTagsChange={handleTagsChange}
              onDateChange={handleDateChange}
              onDateRemove={handleDateRemove}
              onTagsRemove={handleTagsRemove}
              
             
            />

              <NewsPage
              news={resultNews}
              header={"Results"}
              total={totalFilter}
              currentPage={currPgRes}
              onPageChange={handlePageChangeRes} 
              records={21}
            />
              </>
            )}
          
     
       
      </section>
    </div>
    )}
    </>
  )
    
}

export default AdminNewsBookmark
